import { v4 as uuidv4 } from 'uuid';
const singleBaseSalary = 58500
const multiBaseSalary = 83571
const singlePaidBenefits = [
    { 'name': 'ER Long Term Disability','amt': 41,'tooltip':'Disability premiums are calculated on the basepay.'},
    { 'name': 'ER Standard/Basic Life','amt': 32},
    { 'name': 'ER Retirement','amt': 500},
    { 'name': 'ER Health','amt': 423},
    { 'name': 'ER Travel Evacuation','amt': 6},
    { 'name': 'ER Home Equity Retirement', 'amt': 137, 'tooltip':'Home Equity Retirement is an elected long term missionary benefit and based on eligibility. '},
    { 'name': 'ER Workers Compensation','amt': 20},
]
const onePaidBenefits = [
  { 'name': 'ER Long Term Disability','amt': 59},
  { 'name': 'ER Standard/Basic Life','amt': 63},
  { 'name': 'ER Retirement','amt': 750},
  { 'name': 'ER Health','amt': 938, 'withkids': 1090, 'amt2': 938, 'tooltip': '2022 amts may differ, 2021 amts are Employee + Child(ren) 667.00, Employee + Spouse 938.00, Employee Only 423.00, Family 1090.00'},
  { 'name': 'ER Travel Evacuation','amt': 44},
  { 'name': 'ER Home Equity Retirement', 'amt': 137, 'tooltip':'Home Equity Retirement is an elected long term missionary benefit and based on eligibility. '},
  { 'name': 'ER Workers Compensation','amt': 30},

]
const twoPaidBenefits = [
  { 'name': 'ER Long Term Disability','amt': 41,'amt2': 41,},
  { 'name': 'ER Standard/Basic Life','amt': 34,'amt2': 32},
  { 'name': 'ER Retirement','amt': 500,'amt2': 500},
  { 'name': 'ER Health','amt': 423,'withkids':667, 'amt2': 423,'tooltip': '2022 amts may differ, 2021 amts are: Employee + Child(ren) 667.00, Employee + Spouse 938.00, Employee Only 423.00, Family 1090.00'},
  { 'name': 'ER Travel Evacuation','amt': 38,'amt2': 6},
  { 'name': 'ER Home Equity Retirement', 'amt':69,'amt2':69, 'tooltip':'Home Equity Retirement is an elected long term missionary benefit and based on eligibility. '},
  { 'name': 'ER Workers Compensation','amt': 20,'amt2': 20},
]
const baseSalary = (base,state,pos='',paidtype='1') => {
  let colindex = pos !== '' ? pos : '1'
  if(paidtype=='2')
    return (base/12)
      +((parseFloat(state['costofliving'+paidtype+'paid']) || 0)/12)
      +((parseFloat(state['fieldresponsiblity'+pos]) || 0))
      // -((parseFloat(state['ordainedhousing'+pos]) || 0))
      -((parseFloat(state['vsr'+colindex]) || 0))
      +state.personal.filter(a => a.kind == 'grossmonthly').reduce((a,b)=> a+parseFloat(b['amt'+colindex]),0)
  else
    return (base/12)
      +((parseFloat(state['costofliving'+paidtype+'paid']) || 0)/12)
      +((parseFloat(state['fieldresponsiblity'+pos]) || 0))
      // -((parseFloat(state['ordainedhousing'+pos]) || 0))
      -((parseFloat(state['vsr'+colindex]) || 0))
      +state.personal.filter(a => a.kind == 'grossmonthly').reduce((a,b)=> a+parseFloat(b['amt'+pos]),0)
}
const grossSalary = (base,state,pos='') => {
  if(state.ordained && !state.ordainedSSExempt && pos=='')
    return base+seca(base)
  else
    return base
}
const grossAnnualItems = (state,pos='') => {
  return (parseFloat(state.schoolcontributions['schoolpostsecondary'+pos]) || 0)
    +state.personal.filter(a => a.kind == 'grossannual').reduce((a,b)=> a+parseFloat(b['amt'+pos]),0)
}
const taxableSalary = (base,state,pos='') => {
  return base
    -(parseFloat(state['flexiblespendingplan'+pos])||0)
    -(state.personal.filter(a => a.kind == 'pretax').reduce((a,b)=> a+parseFloat(b['amt'+pos]),0))
}
const deductions = (state,pos='') => {
  return ((parseFloat(state.additionalcontributions['lifeinsurance'+pos]) || 0))
    // +((parseFloat(state.ordainedhousing) || 0))
    +((parseFloat(state.additionalcontributions['retirement'+pos]) || 0))
    +((parseFloat(state.additionalcontributions['postretirement'+pos]) || 0))
    +((parseFloat(state.taxes['federalwithholding'+pos]) || 0))
    +((parseFloat(state.taxes['statewithholding'+pos]) || 0))
    +(state.personal.filter(a => a.kind == 'posttax').reduce((a,b)=> a+parseFloat(b['amt'+pos]),0))
}
const netSalary = (base,state,pos='') => {
  let standardSalary = (parseFloat(base) || 0) - deductions(state,pos)
  if(!state.ordained || pos=='2')
    standardSalary = standardSalary - seca(base)
  return standardSalary
}
const seca = (amt) => {
  return ((amt || 0) *0.0765)
}
const getDefaultState = () => {
  return {
    plan: {"plantype":1,"headcount":"2+","name":"Multi-Person Household","description":"Married couples with or without dependents, Singles with dependents at home or in post-secondary education"},
    documents: {
        comparison: true,
        payroll: null,
        supportaccount: null,
    },
    takehometarget: null,
    takehomegross: null,
    costofliving1paid: null,
    costofliving2paid: null,
    vsr1: 0.00,
    vsr2: 0.00,
    vsrBackparse:0,
    kidsinschool: null,
    iswithholding: false,
    taxes: {
        federalwithholding: null,
        federalwithholding1: null,
        federalwithholding2: null,
        federalwithholdingOffset: 50,
        statewithholding: null,
        statewithholding1: null,
        statewithholding2: null,
        statewithholdingOffset: 50,
    },
    leadership: null,
    fieldresponsiblity:null,
    fieldresponsiblity2:null,
    ordained: null,
    ordainedSSExempt: false,
    ordainedhousing: null,
    usbased: false,
    flexiblespendingplan: null,
    flexiblespendingplan1: null,
    flexiblespendingplan2: null,
    flexiblespendingOffset: 50,
    additionalcontributions: {
        retirement: null,
        retirement1: null,
        retirement2: null,
        retirementOffset: 50,
        postretirement: null,
        postretirement2: null,
        lifeinsurance: null,
        lifeinsurance1: null,
        lifeinsurance2: null,
        lifeinsuranceOffset: 50,
    },
    schoolcontributions: {
        school529: null,
        schoolpostsecondary: null,
        schoolpostsecondary1: null,
        schoolpostsecondary2: null,
        schoolpostsecondaryOffset: 50,
    },
    currentStep: 1,
    furthestStep: 0,
    viewTwoToggle: false,
    personal: [],
    supportaccount: {
      balance: null,
      averagemonthly: null
    },
    rounding: 0
  }
}
export default {
  namespaced:true,
  state: getDefaultState(),
  getters: {
    singleBase() {
      return singleBaseSalary
    },
    multiBase() {
      return multiBaseSalary
    },
    singlePaidBaseMonthly(state) {
      return baseSalary(singleBaseSalary,state)
    },
    singlePaidGrossMonthly(state,getters) {
      return grossSalary(getters.singlePaidBaseMonthly,state)
    },
    singlePaidGrossAnnual(state) {
      return grossAnnualItems(state,'')
    },
    singlePaidTaxableMonthly(state,getters) {
      return taxableSalary(getters.singlePaidGrossMonthly,state)
    },
    singlePaidNetMonthly(state,getters) {
      return netSalary(getters.singlePaidTaxableMonthly,state)
    },
    singlePaidVSRTarget(state) {
      if(state.ordained)
        return baseSalary(singleBaseSalary,state)+(parseFloat(state.vsr1)||0)-(((parseFloat(state.takehometarget)||0)+deductions(state))/1.0765)
      else
        return (.9235*(baseSalary(singleBaseSalary,state)+(parseFloat(state.vsr1)||0))-deductions(state)-(parseFloat(state.takehometarget)||0))/.9235
    },
    singlePaidBenefits() {
        return singlePaidBenefits //struct of benefits name/amt
    },
    singlePaidBenefitsTotal(state,getters) {
      return getters.singlePaidBenefits.reduce((acc, benefit) => acc + benefit.amt, 0)
    },
    singlePaidSupportTotal (state,getters) {
      if(!state.ordained)
        return getters.singlePaidBenefitsTotal + getters.singlePaidGrossMonthly + seca(getters.singlePaidTaxableMonthly)
      else
        return getters.singlePaidBenefitsTotal + getters.singlePaidGrossMonthly
    },
    getDeductions(state) {
      return deductions(state)
    },
    onePaidBaseMonthly(state) {
      return baseSalary(multiBaseSalary,state)
    },
    onePaidGrossMonthly(state,getters) {
      return grossSalary(getters.onePaidBaseMonthly,state)
    },
    onePaidGrossAnnual(state) {
      return grossAnnualItems(state,'')
    },
    onePaidTaxableMonthly(state,getters) {
      return taxableSalary(getters.onePaidGrossMonthly,state)
    },
    onePaidNetMonthly(state,getters) {
      return netSalary(getters.onePaidTaxableMonthly,state)
    },
    onePaidVSRTarget(state) {
      if(state.ordained)
        return baseSalary(multiBaseSalary,state)+(parseFloat(state.vsr1)||0)-(((parseFloat(state.takehometarget)||0)+deductions(state))/1.0765)
      else
        return (.9235*(baseSalary(multiBaseSalary,state)+(parseFloat(state.vsr1)||0))-deductions(state)-(parseFloat(state.takehometarget)||0))/.9235
    },
    onePaidBenefits(state) {
      return onePaidBenefits.map(b => {
        if(typeof b.withkids !== 'undefined')
          b.amt = state.kidsinschool === true ? b.withkids : b.amt2
        return b
      }) //array of benefits name/amt
    },
    onePaidBenefitsTotal(state,getters) {
      return getters.onePaidBenefits
        .reduce((acc, benefit) => acc + benefit.amt, 0)
        +(state.personal.filter(a => a.kind == 'benefit').reduce((a,b)=> a+parseFloat(b.amt),0))
    },
    onePaidSupportTotal (state,getters) {
      return !state.ordained ? (getters.onePaidTaxableMonthly*0.0765)+getters.onePaidBenefitsTotal+getters.onePaidGrossMonthly : getters.onePaidBenefitsTotal+getters.onePaidGrossMonthly
    },
    twoPaid1BaseMonthly(state) {
      return baseSalary(singleBaseSalary,state,'','2') //spouse=1,paidtype = 2
    },
    twoPaid1GrossMonthly(state,getters) {
      return grossSalary(getters.twoPaid1BaseMonthly,state)
    },
    twoPaid1GrossAnnual(state) {
      return grossAnnualItems(state,'1')
    },
    twoPaid1TaxableMonthly(state,getters) {
      return taxableSalary(getters.twoPaid1GrossMonthly,state,'1')
    },
    twoPaid1NetMonthly(state,getters) {
      return netSalary(getters.twoPaid1TaxableMonthly,state,'1')
    },
    twoPaid2BaseMonthly(state) {
      return baseSalary(singleBaseSalary,state,2,2) //spouse=2,paidtype = 2
    },
    twoPaid2GrossMonthly(state,getters) {
      return grossSalary(getters.twoPaid2BaseMonthly,state,'2')
    },
    twoPaid2GrossAnnual(state) {
      return grossAnnualItems(state,'2')
    },
    twoPaid2TaxableMonthly(state,getters) {
      return taxableSalary(getters.twoPaid2GrossMonthly,state,'2')
    },
    twoPaid2NetMonthly(state,getters) {
      return netSalary(getters.twoPaid2TaxableMonthly,state,'2')
    },
    twoPaidVSRTarget(state) {
      if(state.ordained) //ordained formula + standard
        return baseSalary(singleBaseSalary,state)+(parseFloat(state.vsr1)||0)-(((parseFloat(state.takehometarget/2)||0)+deductions(state))/1.0765)
          +(.9235*(baseSalary(singleBaseSalary,state,'2','2')+(parseFloat(state.vsr2)||0))-deductions(state,'2')-(parseFloat(state.takehometarget/2)||0))/.9235
      else //two standard formulas
        return (.9235*(baseSalary(singleBaseSalary,state)+(parseFloat(state.vsr1)||0))-deductions(state)-(parseFloat(state.takehometarget/2)||0))/.9235
          +(.9235*(baseSalary(singleBaseSalary,state,'2','2')+(parseFloat(state.vsr2)||0))-deductions(state,'2')-(parseFloat(state.takehometarget/2)||0))/.9235
    },
    twoPaidBenefits(state) {
      return twoPaidBenefits.map(b => {
        if(typeof b.withkids !== 'undefined')
          b.amt = state.kidsinschool === true ? b.withkids : b.amt2
        return b
      }) //array of benefits name/amt
    },
    twoPaidBenefitsTotal(state,getters) {
      return getters.twoPaidBenefits
        .reduce((acc, benefit) => acc + benefit.amt+ benefit.amt2, 0)
        +(state.personal.filter(a => a.kind == 'benefit').reduce((a,b)=> a+parseFloat(b.amt),0))
    },
    twoPaid1SupportTotal (state,getters) {
        if(!state.ordained)
          return seca(getters.twoPaid1TaxableMonthly)
          +getters.twoPaid1GrossMonthly
          +getters.twoPaidBenefits.reduce((a,b)=> a+b.amt,0)
          +(state.personal.filter(a => a.kind == 'benefit').reduce((a,b)=> a+parseFloat(b.amt1),0))
        else
          return getters.twoPaid1GrossMonthly
          +getters.twoPaidBenefits.reduce((a,b)=> a+b.amt,0)
          +(state.personal.filter(a => a.kind == 'benefit').reduce((a,b)=> a+parseFloat(b.amt1),0))
    },
    twoPaid2SupportTotal (state,getters) {
        return seca(getters.twoPaid2TaxableMonthly)
        +getters.twoPaid2GrossMonthly+getters.twoPaidBenefits.reduce((a,b)=> a+parseFloat(b.amt2),0)
        +(state.personal.filter(a => a.kind == 'benefit').reduce((a,b)=> a+parseFloat(b.amt2),0))
    },
    supportMonthly(state,getters) {
      if(!getters.isTwoPlan) // single
        return getters.singlePaidSupportTotal
      else if(getters.isTwoPlan && !state.viewTwoToggle) //multi one-paid
        return getters.onePaidSupportTotal
      else //multi two-paid
        return getters.twoPaid1SupportTotal + getters.twoPaid2SupportTotal
    },
    supportAnnual(state,getters) {
      return (getters.supportMonthly*12)+grossAnnualItems(state,'')
    },
    grossMonthly(state,getters) {
      if(!getters.isTwoPlan) // single
        return getters.singlePaidGrossMonthly
      else if(getters.isTwoPlan && !state.viewTwoToggle) //multi one-paid
        return getters.onePaidGrossMonthly
      else //multi two-paid
        return getters.twoPaid1GrossMonthly + getters.twoPaid2GrossMonthly
    },
    grossAnnual(state,getters) {
      return (getters.grossMonthly*12)+grossAnnualItems(state,'')
    },
    grossAnnualTotal(state) {
      return grossAnnualItems(state,'')
    },
    grossPercentDiff(state,getters) {
      return parseFloat(((1-(state.takehomegross/getters.grossAnnual))*100))
    },
    netMonthly(state,getters) {
      if(!getters.isTwoPlan) // single
        return getters.singlePaidNetMonthly
      else if(getters.isTwoPlan && !state.viewTwoToggle) //multi one-paid
        return getters.onePaidNetMonthly
      else //multi two-paid
        return getters.twoPaid1NetMonthly + getters.twoPaid2NetMonthly
    },
    netPercentDiff(state,getters) {
      return parseFloat((((state.takehometarget/getters.netMonthly)-1)*-100))
    },
    supportBurnRate(state,getters) {
      return parseFloat(state.supportaccount.balance)/(parseFloat(state.supportaccount.averagemonthly)-(getters.supportAnnual/12))
    },
    supportRaiseAmt(state,getters) {
      return parseFloat(state.supportaccount.averagemonthly)-(getters.supportAnnual/12)
    },
    supportExists(state) {
      return (parseFloat(state.supportaccount.balance) > 0 && parseFloat(state.supportaccount.averagemonthly) > 0)
    },
    netAnnual(state,getters) {
      return getters.netMonthly*12
    },
    vsrMonthly(state) {
      return (parseFloat(state.vsr1) || 0)+(parseFloat(state.vsr2) || 0)
    },
    furthestStep(state) {
      return state.furthestStep;
    },
    isTwoPlan(state) {
      return state.plan.plantype == 1;
    },
    isTwoToggled(state) {
      return state.viewTwoToggle
    },
    isOrdained(state) {
      return state.ordained
    },
    isOrdainedExempt(state) {
      return state.ordainedSSExempt
    },
    personalItems(state) {
      return state.personal
    }
  },
  actions: {
    // Prevew Page Actions
    togglePlan({commit},twopaytoggle) {
      commit('togglePreviewPlan',twopaytoggle)
    },
    // Wizard Data Update
    updateData({commit},piece) { //context replaces all state functions (e.g., context or state)
      commit('setData',piece)
    },
    // Wizard Form Navigation Guards
    updateUserStep({commit},stepname) {
      commit('setCurrentStep',stepname)
    },
    maxFurthestStep({commit},stepnumber) {
      commit('pushFurthestStep',stepnumber)
    },
    resetFurthestStep({commit},stepnumber) {
      commit('setFurthestStep',stepnumber)
    },
    // personal items to add/edit/remove
    addPersonal({commit},item) {
      if(!item.id) item.id = uuidv4()
      commit('setPersonal',item)
      return item.id
    },
    removePersonal({commit},item) {
      commit('deletePersonal',item)
    },
    editPersonal({commit},item) {
      commit('updatePersonal',item)
    },
    resetEstimator({commit}) {
      commit('resetState')
    },
    updateTakeHome({commit},takehometarget) {
      commit('setTakeHome',takehometarget)
    }
  },
  mutations: {
    togglePreviewPlan(state,twopaytoggle) {
      state.viewTwoToggle = twopaytoggle
      // toggling vsr
      if(!twopaytoggle) {
        state.vsrBackparse = (parseInt(state.vsr1)+parseInt(state.vsr2))/parseInt(state.vsr2)
        state.vsr1 = (parseFloat(state.vsr2) + parseFloat(state.vsr1)).toFixed(2)
        state.vsr2 = 0.0
      }
      if(twopaytoggle && state.vsrBackparse && state.vsrBackparse > 0) {
          state.vsr2 = (parseFloat(state.vsr1) / parseFloat(state.vsrBackparse)).toFixed(2)
          state.vsr1 = (parseFloat(state.vsr1) - parseFloat(state.vsr2)).toFixed(2);
      }
    },
    setData(state, piece) {
      // add portion of the estimator
      Object.assign(state, piece)
    },
    // Wizard Navigation
    setCurrentStep(state, stepname) {
      state.currentStep = stepname
    },
    setFurthestStep(state, stepnumber) {
      state.furthestStep = stepnumber
    },
    pushFurthestStep(state, stepnumber) {
      if(stepnumber > state.furthestStep)
        state.furthestStep = stepnumber
    },
    //personal items
    setPersonal(state,item) {
      state.personal.push(item)
    },
    deletePersonal(state,item) {
      state.personal = state.personal.filter(el => el.id !==item.id)
    },
    updatePersonal(state,item) {
      state.personal = state.personal.filter(el => el.id !==item.id)
      state.personal.push(item)
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setTakeHome(state,takehometarget) {
      state.takehometarget = takehometarget
    },
  }

}
