<template>
  <div class="mt-1 relative" :class="(item[itemType])? 'font-bold ':'font-base'">
    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" >
    <span class="text-gray-500 sm:text-sm">
        {{currentSymbol}}
    </span>
    </div>
    <input v-model="item[itemType]" @keyup="update(item.id)" type="number" name="price" id="price" class="block w-full bg-gray-50 pl-7 sm:text-sm border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 bg-gray-50" placeholder="0.00" aria-describedby="price-currency" :class="backgroundStyle">
    <div class="absolute inset-y-0 right-0 flex items-center" :class="(currency)? 'ml-14':''" >
      <label for="budgetItem" class="sr-only">budgetItem</label>
      <select v-if="currency" @input="changeCurrency" id="budgetItem" name="budgetItem" class="focus:ring-indigo-400 focus:border-indigo-400 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm">
        <option :selected="(!isSecondarySelected)?'selected':''" value="">USD</option>
        <option  :selected="(isSecondarySelected)?'selected':''" :value="currencyOffset">{{currency}}</option>
      </select>

    </div>
  </div>
</template>
<script>
const getSymbolFromCurrency = require('currency-symbol-map')
const defaultCurrency = "USD"
//const delay = ms => new Promise(res => setTimeout(res, ms));
export default {
  name: 'MoneyInput',
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    currency: {
      type: String,
      required: false,
      default:''
    },
    currencyOffset: Number,
    itemType: {
      type: String,
      required: true,
      default: 'month'
    }
  },
  data() {
    return {
      selectedCurrency: defaultCurrency
    }
  },
  mounted () {
    this.selectedCurrency = (this.isSecondarySelected) ? this.currency : defaultCurrency
  },
  watch: {
    currency() {
      this.selectedCurrency  = (this.isSecondarySelected) ? this.currency : defaultCurrency
    }
  },
  computed: {
    isSecondarySelected() {
      return (this.itemType==="month" && this.isSpecialMonth) || (this.isSpecialAnnual && this.itemType ==="annual")
    },
    isSpecialMonth() {
      return (this.item.monthOffset==null || this.item.monthOffset.length ===0 ) ? false : true
    },
    isSpecialAnnual() {
      return (this.item.annualOffset==null || this.item.annualOffset.length ===0 ) ? false : true
    },
    currentCurrency() {
      return this.selectedCurrency
    },
    currentSymbol() {
      return getSymbolFromCurrency(this.currentCurrency)
    },
    backgroundStyle () {
      if(this.isSecondarySelected && this.item[this.itemType])
        return 'bg-green-50'
      else if (this.item[this.itemType])
        return 'bg-blue-50'
      else
        return 'bg-gray-50'
    }
  },
  methods: {
    update() {
      this.$emit('update',this.item.id)
    },
    changeCurrency(event) {
      //update the local values.
      this.selectedCurrency = event.target.options[event.target.selectedIndex].text

      // set the currency and evething here
      this.$emit('swapCurrency',this.item.id,this.itemType,event.target.value)
    }
  }
}
</script>