<template>
    <div class="fixed z-20 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen max-w-lg pt-4 px-4 pb-20 text-center sm:block sm:p-0 mx-auto">

        <transition name="fade" mode="out-in" >
          <div v-if="showModal" @click="closeMe" class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-300 opacity-75"></div>
          </div>
        </transition>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <!--
          Modal panel, show/hide based on modal state.
        -->
        <transition name="fade" mode="out-in" >
          <div v-if="showModal" class="inline-block align-bottom bg-white rounded-lg mt-24 text-left w-full max-h-96 shadow-xl overflow-auto transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:mt-8" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
             <!-- HEADER --->
            <div class="sticky top-0 z-50 bg-blue-50 px-4 py-4 border-b border-gray-200 sm:px-6">
              <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button @click="closeMe" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="sr-only">Close</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <h4 class="text-large w-48 inline overflow-hidden capitalize">{{ action }} {{kind}}</h4>
            </div>
            <div class="h-screen md:h-24 overflow-y-auto sm:px-0 flex flex-col gap-2 md:items-center md:justify-between md:flex-row">
                <div class="w-full md:w-4/5 px-5">
                    <div class="mt-1">
                        <input  v-model="$v.item.name.$model" type="text" name="name" id="name"  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="">
                    </div>
                    <TWAlert v-if="$v.item.name.$error && !$v.item.name.required" alerttype="mini" message="A description is required"></TWAlert>
                </div>
                <div class="w-full md:w-1/5 px-5 pt-2 z-0 inline-flex">
                    <button type="button" @click="saveItem()" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-xs leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Save
                    </button>
                     <button v-if="isEdit" type="button" @click="deleteItem()" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-xs leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
</svg>
                    </button>
                </div>
            </div>
            <div v-if="showMessage" class="rounded-md bg-green-50 p-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <!-- Heroicon name: solid/check-circle -->
                  <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="ml-3">
                  <p class="text-sm font-medium text-green-800">
                  {{message}}
                  </p>
                </div>

              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
</template>
<script>
import {mapActions} from 'vuex'
import {required} from 'vuelidate/lib/validators'
import TWAlert from './TWAlert.vue'
const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
  name:'BudgetItem',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    area: String,
    category: String,
    name: String,
    id: Number,
    kind: String,
  },
  components: {
      TWAlert
  },
  data () {
    return {
        message: null,
        showMessage: false,
        item: {
            id:null,
            name: null,
            area: null,
            category: null,
        }
      }
  },
  validations: {
    item: {
        name: {
            required
        }
    }

  },
  mounted() {
    if(this.id) {
      this.item.id = this.id
    }

  },
  computed: {
      isEdit() {
          return (this.item.id) ? true : false
      },
      action() {
        return (this.isEdit) ? "Edit" : "Add"
      }
  },
  methods: {
    ...mapActions({
      addCategory: 'estimator/addCategory',
    }),
    closeMe () {
      this.$emit('close')
    },
    async flashMessage (msg) {
        this.message = msg
        this.showMessage = true
        await delay(3000)
        this.showMessage = false
        this.closeMe()
    },
    async saveItem () {
       this.$v.$touch()
        if(!this.$v.$invalid) {
            this.item.amt1 = this.item.amt*(this.item.offset/100)
            this.item.amt2 = this.item.amt*((100-this.item.offset)/100)
            if(this.item.id) {
                await this.editPersonal(this.item)
                this.flashMessage('Item updated.')
            }
            else {
                this.item.kind = this.kind
                const newid = await this.addPersonal(this.item)
                this.item.id = newid
                this.flashMessage('New item added successfully.')
            }

        }
    },
    async deleteItem () {
      await this.removePersonal(this.item)
      this.item.id = null
      this.flashMessage('Item removed.')
    }
  }
}
</script>